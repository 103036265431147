import React from 'react'
import { type MemoVisibility, type MemoOrdering } from './ICandooSettings'
import { type TreeNode } from './VisibilityTree/TreeTypes'

export class VisibilityMapper {
  getDefaultMemoOrdering(): MemoOrdering[] {
    return [
      {
        id: VisibilityKey.case,
        children: [
          { id: VisibilityKey.citation },
          { id: VisibilityKey.plaintiff },
          { id: VisibilityKey.defendant },
          { id: VisibilityKey.judges },
          { id: VisibilityKey.court },
          { id: VisibilityKey.decision_date }
        ]
      },
      {
        id: VisibilityKey.summary
      },
      {
        id: VisibilityKey.facts
      },
      {
        id: VisibilityKey.arguments
      },
      {
        id: VisibilityKey.issues,
        children: [
          { id: VisibilityKey.ratio },
          { id: VisibilityKey.analysis },
          { id: VisibilityKey.outcome }
        ]
      }
    ]
  }

  getDefaultVisibility(): MemoVisibility {
    return {
      memoSummaryVisible: true,
      memoFactsVisible: true,

      memoIssuesVisible: true,
      memoIssuesRatioVisible: true,
      memoIssuesAnalysisVisible: true,
      memoIssuesOutcomeVisible: true,

      memoCitationVisible: true,

      memoCaseVisible: true,
      memoCasePlaintiffVisible: true,
      memoCaseDefendantVisible: true,
      memoCaseJudgesVisible: true,
      memoCaseCourtVisible: true,
      memoCaseDecisionDateVisible: true,

      memoArgumentsVisible: true
    }
  }

  getNonDraggableKeys(): string[] {
    return [VisibilityKey.ratio, VisibilityKey.analysis, VisibilityKey.outcome]
  }

  getAllKeys(): string[] {
    return Object.keys(VisibilityKey)
  }

  /**
   *  Convert the data to a format suitable for Tree UI
   * */
  toTreeNode(items: MemoOrdering[] | undefined): TreeNode[] {
    if (items === undefined) return []

    const traverse = (item: MemoOrdering, parent?: TreeNode): TreeNode => {
      const isLeaf = item.children == null || item.children.length === 0

      const element: TreeNode = {
        key: item.id,
        title: <>{this.toCamelCase(item.id)}</>,
        isLeaf,
        // icon: isLeaf ? <></> : undefined,
        parent
      }

      element.children =
        item.children != null
          ? item.children.map((child) => traverse(child, element))
          : undefined

      return element
    }

    const result: TreeNode[] = items.map((x) => traverse(x))

    return result
  }

  toChecked(settings: MemoVisibility, prevCheckedIds: string[]): string[] {
    const checkedIds = new Set(prevCheckedIds)

    const methodMap: { [key in 'add' | 'delete']: (key: VisibilityKey) => void } = {
      add: checkedIds.add.bind(checkedIds),
      delete: checkedIds.delete.bind(checkedIds)
    }

    const stringify = (flag: boolean): 'add' | 'delete' => { return flag ? 'add' : 'delete' }

    const update = (flag: boolean, key: VisibilityKey): void => {
      const addOrDelete = methodMap[stringify(flag)]
      addOrDelete(key)
    }

    update(settings.memoSummaryVisible, VisibilityKey.summary)
    update(settings.memoFactsVisible, VisibilityKey.facts)
    update(settings.memoIssuesVisible, VisibilityKey.issues)
    update(settings.memoIssuesRatioVisible, VisibilityKey.ratio)
    update(settings.memoIssuesAnalysisVisible, VisibilityKey.analysis)
    update(settings.memoIssuesOutcomeVisible, VisibilityKey.outcome)
    update(settings.memoCitationVisible, VisibilityKey.citation)
    update(settings.memoCaseVisible, VisibilityKey.case)
    update(settings.memoCasePlaintiffVisible, VisibilityKey.plaintiff)
    update(settings.memoCaseDefendantVisible, VisibilityKey.defendant)
    update(settings.memoCaseJudgesVisible, VisibilityKey.judges)
    update(settings.memoCaseCourtVisible, VisibilityKey.court)
    update(settings.memoCaseDecisionDateVisible, VisibilityKey.decision_date)
    update(settings.memoArgumentsVisible, VisibilityKey.arguments)

    return Array.from(checkedIds)
  }

  fromChecked(prevCheckedIds: string[]): MemoVisibility {
    const checkedIds = new Set(prevCheckedIds)

    return {
      memoSummaryVisible: checkedIds.has(VisibilityKey.summary),
      memoFactsVisible: checkedIds.has(VisibilityKey.facts),

      memoIssuesVisible: checkedIds.has(VisibilityKey.issues),
      memoIssuesRatioVisible: checkedIds.has(VisibilityKey.ratio),
      memoIssuesAnalysisVisible: checkedIds.has(VisibilityKey.analysis),
      memoIssuesOutcomeVisible: checkedIds.has(VisibilityKey.outcome),

      memoCitationVisible: checkedIds.has(VisibilityKey.citation),

      memoCaseVisible: checkedIds.has(VisibilityKey.case),
      memoCasePlaintiffVisible: checkedIds.has(VisibilityKey.plaintiff),
      memoCaseDefendantVisible: checkedIds.has(VisibilityKey.defendant),
      memoCaseJudgesVisible: checkedIds.has(VisibilityKey.judges),
      memoCaseCourtVisible: checkedIds.has(VisibilityKey.court),
      memoCaseDecisionDateVisible: checkedIds.has(VisibilityKey.decision_date),

      memoArgumentsVisible: checkedIds.has(VisibilityKey.arguments)
    }
  }

  fromTreeNodes(treeData: TreeNode[]): MemoOrdering[] {
    return treeData.map((node) => {
      const element = {
        id: node.key as string,
        children: node.children != null && node.children.length > 0 ? this.fromTreeNodes(node.children) : undefined
      }

      return element
    })
  }

  private toCamelCase(id: string): string {
    return id
      .split('_')
      .map((x) => x[0].toUpperCase() + x.slice(1))
      .join('')
  }
}

export enum VisibilityKey {
  case = 'case',
  citation = 'citation',
  plaintiff = 'plaintiff',
  defendant = 'defendant',
  judges = 'judges',
  court = 'court',
  decision_date = 'decision_date',
  summary = 'summary',
  facts = 'facts',
  arguments = 'arguments',
  issues = 'issues',
  ratio = 'ratio',
  analysis = 'analysis',
  outcome = 'outcome',
}
