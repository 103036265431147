import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { type UserProfile } from '../ProfileInterface'
import './TabSelection.css'
import CandooSettings from '../CandooSettings/CandooSettings'
import useEditUserProfile from '../UserProfile/useEditUserProfile'
import ProfileView from '../UserProfile/ProfileView'
import useCandooSettings from '../CandooSettings/useCandooSettings'

interface Props {
  userProfile: UserProfile
  fetchUserProfile: () => void
}

enum TabId {
  UserSettings = 0,
  CandooSettings = 1
}

const DEFAULT_TAB = TabId.UserSettings

const ENVIRONMENT: string | undefined =
    process.env.REACT_APP_ENV ?? process.env.NODE_ENV

const isDev = ENVIRONMENT === 'dev' || ENVIRONMENT === 'development'
const isStaging = ENVIRONMENT === 'staging'
const showCandoo = isDev || isStaging

const TabSelection: React.FC<Props> = ({ userProfile, fetchUserProfile }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentTab = getTabId(searchParams.get('tabId'), DEFAULT_TAB)
  const [selectedTab, setSelectedTab] = useState(currentTab.valid ? currentTab.tabId : DEFAULT_TAB)

  const candooProperties = useCandooSettings()

  const userProfileProperties = useEditUserProfile(userProfile, fetchUserProfile)

  useEffect(() => {
    // reset url search param: tabId to default in case it is not defined
    if (!currentTab.valid) {
      setSearchParams({ tabId: TabId[DEFAULT_TAB] })
    }
  }, [currentTab.valid, setSearchParams])

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setSelectedTab(newValue)
    setSearchParams({ tabId: TabId[newValue] })
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex'
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={selectedTab}
        onChange={handleChange}
        aria-label="settings-selection"
        sx={{ borderRight: 1, borderColor: 'divider', minWidth: 'fit-content' }}
      >
        <Tab
          label="User settings"
          className={'tab-btn'}
          {...a11yProps(TabId.UserSettings)}
        />
        <Tab
          label="Candoo settings"
          className={'tab-btn'}
          disabled={!showCandoo}
          {...a11yProps(TabId.CandooSettings)}
        />
      </Tabs>
      <div className="container">
        <TabPanel value={selectedTab} index={TabId.UserSettings}>
          <ProfileView {...userProfileProperties} />
        </TabPanel>
        {showCandoo && (
          <TabPanel value={selectedTab} index={TabId.CandooSettings}>
            <CandooSettings {...candooProperties} />
          </TabPanel>
        )}
      </div>
    </Box>
  )
}

interface Ia11yProps {
  id: string
  'aria-controls': string
}
function a11yProps (index: number): Ia11yProps {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      className="row tab-panel"
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index ? children : null}
    </div>
  )
}

export default TabSelection

/**
 * @param value - string to be converted to Enum
 * @returns Enum matching the string representation or default if there is no match
 */
function getTabId (value: string | null, defaultTabId: TabId): { tabId: TabId, valid: boolean } {
  if (value == null) return { tabId: defaultTabId, valid: false }

  const originalNames: string[] = []
  const lowerCaseNames = Object.values(TabId).filter(x => typeof x === 'string')
    .map(x => {
      const s = x as string
      originalNames.push(s)
      return s.toLowerCase()
    })

  const index = lowerCaseNames.indexOf(value.toLowerCase())

  if (index < 0) return { tabId: defaultTabId, valid: false }

  return {
    tabId: TabId[originalNames[index] as keyof typeof TabId],
    valid: true
  }
}
